import React from "react";
import LazyLoad from "react-lazyload";
import { graphql, Link } from "gatsby";

import { withContext } from "~context";
import { BreadCrumbs } from "~components/assessment/bread-crumbs";
import { AssessmentVoiceList } from "~components/assessment/voice-list";
import { Assessment2PaneLayout } from "~components/templates/assessment-2pane-layout/page";
import "./index.scss";
import { AssessmentArticleList } from "~components/articles/assessment-article-list";
import { PriceDocBanner } from "~components/assessment/price_doc_banner";
import { AssessmentDocContact } from "~components/assessment/price_doc_contact";
import { Section, SubSection } from "~components/templates/section";
import { Common2PaneSectionLayout } from "~components/templates/common-2pane-layout/section-layout";
import { DocumentStyle } from "~components/templates/document-style";
import { FaqStyle } from "~components/templates/faq-style";
import { TableStyle } from "~components/templates/table-style";
import { Interview } from "~components/utils/interview";

type Props = {
  data: GatsbyFirebasePageQuery;
};

const Component: React.FC<Props> = (props) => {
  const { data } = props;
  const CONTACT_FORM_ID =
    "31lfBkuItiC6zUlXxuv94ntAG0HPusBDbhRIM9Ck5f5X7VEJlguDsXpREs7pipVn5";

  return (
    <>
      <PriceDocBanner />
      <Assessment2PaneLayout
        formId={CONTACT_FORM_ID}
        meta={{
          title: "Firebase診断",
          description:
            "Firebaseを対象に独自の脆弱性診断を実施します。Firestore や Firebase Storage へのアクセス権限を規定するセキュリティルールを正しく定義するとともに、 Cloud Functions の実装内容や Firebase Authentication における認証のポリシー、 Firebase Hosting へのデプロイに用いる構成情報といった事項がセキュアに保たれているかを確認します。",
        }}
      >
        <div className="Firebase AssessmentCommonPage">
          <LazyLoad>
            <div className="AssessmentCommonTop FirebaseTop">
              <div className="AssessmentCommonTop_Wrapper">
                <Common2PaneSectionLayout>
                  <DocumentStyle width="no-limit">
                    <h1>Firebase診断</h1>
                    <p>
                      Firebaseを対象に独自の脆弱性診断を実施します。Firestore や
                      Firebase Storage
                      へのアクセス権限を規定するセキュリティルールを正しく定義するとともに、
                      Cloud Functions の実装内容や Firebase Authentication
                      における認証のポリシー、 Firebase Hosting
                      へのデプロイに用いる構成情報といった事項がセキュアに保たれているかを確認します。
                    </p>
                  </DocumentStyle>
                </Common2PaneSectionLayout>
              </div>
            </div>
          </LazyLoad>
          <BreadCrumbs title="Firebase診断" />
          <LazyLoad>
            <Section>
              <Common2PaneSectionLayout>
                <DocumentStyle width="no-limit">
                  <h2>診断対象</h2>
                </DocumentStyle>
                <ul className="Firebase_TargetList">
                  <li className="Firebase_TargetListItem">Cloud Firestore</li>
                  <li className="Firebase_TargetListItem">
                    Firebase Realtime Database
                  </li>
                  <li className="Firebase_TargetListItem">
                    Cloud Storage for Firebase
                  </li>
                  <li className="Firebase_TargetListItem">
                    Firebase Authentication
                  </li>
                  <li className="Firebase_TargetListItem">Firebase Hosting</li>
                  <li className="Firebase_TargetListItem">
                    Cloud Functions for Firebase
                  </li>
                </ul>
                <img
                  className="Firebase_TargetChart"
                  src={data?.chart?.publicURL || ""}
                  alt="Firebaseをバックエンドに用いたサーバレス構成の概念図"
                />
                <DocumentStyle width="no-limit">
                  <p>
                    Firebase単体を診断するケースでの診断対象です。Firebaseと組み合わせて使用するクライアント(WebのフロントエンドやiOS/Androidアプリ)も併せて診断することを推奨しています。
                  </p>
                </DocumentStyle>
              </Common2PaneSectionLayout>
            </Section>
          </LazyLoad>

          <LazyLoad>
            <Section>
              <Common2PaneSectionLayout>
                <DocumentStyle>
                  <h2>診断観点</h2>
                  <TableStyle>
                    <SubSection>
                      <h3>
                        Cloud Firestore / Firebase Realtime Database / Cloud
                        Storage for Firebase
                      </h3>
                      <table>
                        <tbody>
                          <tr>
                            <th>認証</th>
                            <td>
                              オープンアクセス
                              <br />
                              <br />
                              認証の不備
                            </td>
                          </tr>
                          <tr>
                            <th>バリデーション</th>
                            <td>
                              作成及び更新されるデータのバリデーションの不備
                            </td>
                          </tr>
                          <tr>
                            <th>権限</th>
                            <td>システム要件に基づく権限の不備</td>
                          </tr>
                          <tr>
                            <th>ロジック</th>
                            <td>システム要件に基づくロジック上の脆弱性</td>
                          </tr>
                        </tbody>
                      </table>
                    </SubSection>
                    <SubSection>
                      <h3>Firebase Authentication</h3>
                      <table>
                        <tbody>
                          <tr>
                            <th>認証</th>
                            <td>認証ポリシーのチェック</td>
                          </tr>
                        </tbody>
                      </table>
                      <h3>Firebase Hosting</h3>
                      <table>
                        <tbody>
                          <tr>
                            <th>構成</th>
                            <td>デプロイ構成のチェック</td>
                          </tr>
                        </tbody>
                      </table>
                      <h3>Cloud Functions for Firebase</h3>
                      <table>
                        <tbody>
                          <tr>
                            <th>認証</th>
                            <td>
                              オープンアクセス
                              <br />
                              <br />
                              認証の不備
                            </td>
                          </tr>
                          <tr>
                            <th>バリデーション</th>

                            <td>
                              作成及び更新されるデータのバリデーションの不備
                            </td>
                          </tr>
                          <tr>
                            <th>権限</th>
                            <td>システム要件に基づく権限の不備</td>
                          </tr>
                          <tr>
                            <th>ロジック</th>
                            <td>システム要件に基づくロジック上の脆弱性</td>
                          </tr>
                          <tr>
                            <th>構成</th>
                            <td>脆弱性のあるライブラリのチェック</td>
                          </tr>
                        </tbody>
                      </table>
                    </SubSection>
                    <SubSection>
                      <h3>クライアント</h3>
                      <table>
                        <tbody>
                          <tr>
                            <th>Webの場合</th>
                            <td>SPAのセキュリティ等</td>
                          </tr>
                          <tr>
                            <th>iOS/Androidアプリの場合</th>
                            <td>
                              <Link
                                to="/assessment/sp_app"
                                className="Firebase_Link"
                              >
                                スマートフォンアプリケーション診断
                              </Link>
                              で対応
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </SubSection>
                  </TableStyle>
                </DocumentStyle>
              </Common2PaneSectionLayout>
            </Section>
          </LazyLoad>

          <LazyLoad>
            <Interview title="Firebase診断の事例インタビュー">
              <AssessmentVoiceList
                slugs={["goodpatch", "sumilena", "en-japan", "claudio", "ubie"]}
              />
            </Interview>
          </LazyLoad>

          <LazyLoad>
            <Section background="brand">
              <Common2PaneSectionLayout>
                <DocumentStyle>
                  <h2>料金</h2>
                  <h4>
                    ヒアリングとお見積もりを通して最終的な料金を決定させていただきます。
                  </h4>
                  <p>
                    Firebase診断では診断内容と規模に応じた都度のお見積りを実施いたします。
                  </p>
                </DocumentStyle>
              </Common2PaneSectionLayout>
            </Section>
          </LazyLoad>
          <AssessmentDocContact />
          <LazyLoad>
            <Section>
              <Common2PaneSectionLayout>
                <DocumentStyle>
                  <h2>技術ブログ公開中</h2>
                  <p>
                    Firebaseの概要やセキュリティルールを用いた堅牢なCloud
                    Firestore環境の構築について説明している技術ブログを公開中です。ぜひご覧ください。
                  </p>
                  <div className="AssessmentCommonBlog_Item">
                    <a href="https://flattsecurity.hatenablog.com/entry/2020/04/10/122834">
                      <img
                        className="AssessmentCommonBlog_Image"
                        src="https://cdn-ak.f.st-hatena.com/images/fotolife/f/flattsecurity/20200409/20200409001242.png"
                        alt="技術ブログ 「AWS 診断を事例としたクラウドセキュリティ。サーバーレス環境の不備や見落としがちな Cognito の穴による危険性」"
                      />
                      https://flattsecurity.hatenablog.com/entry/2020/04/10/122834
                    </a>
                  </div>
                  <div className="AssessmentCommonBlog_Item">
                    <a href="https://blog.flatt.tech/entry/firestore_security_rules">
                      <img
                        className="AssessmentCommonBlog_Image"
                        src="https://cdn-ak.f.st-hatena.com/images/fotolife/f/flattsecurity/20210216/20210216172658.png"
                        alt="Firestoreセキュリティルールの基礎と実践 - セキュアな Firebase活用に向けたアプローチを理解する"
                      />
                      https://blog.flatt.tech/entry/firestore_security_rules
                    </a>
                  </div>
                  <div className="AssessmentCommonBlog_Item">
                    <a href="https://blog.flatt.tech/entry/firebase_authentication_security">
                      <img
                        className="AssessmentCommonBlog_Image"
                        src="https://cdn-ak.f.st-hatena.com/images/fotolife/f/flattsecurity/20220418/20220418095225.jpg"
                        alt="Firebase Authentication 7つの落とし穴 - 脆弱性を生むIDaaSの不適切な利用技術 セキュリティ診断 Firebase"
                      />
                      https://blog.flatt.tech/entry/firebase_authentication_security
                    </a>
                  </div>
                </DocumentStyle>
              </Common2PaneSectionLayout>
            </Section>
          </LazyLoad>
          <AssessmentArticleList
            data={{
              articles: data.allContentfulArticle.nodes,
              category: data.contentfulArticleCategory,
            }}
          />
          <LazyLoad>
            <Section background="subtle-gray">
              <Common2PaneSectionLayout>
                <FaqStyle>
                  <h2>よくある質問</h2>
                  <div>
                    <h3>どのような診断を行いますか？</h3>
                    <p>
                      ソースコードを診断するホワイトボックス形式の診断を行います。
                    </p>
                  </div>
                  <div>
                    <h3>
                      Webアプリケーション診断など他の診断と組み合わせることは可能ですか？
                    </h3>
                    <p>
                      Firebase単体での診断も可能ですが、Firebaseと組み合わせて使用しているGoogle
                      CloudやAWS等クラウドの設定、クラウド上で動作しているアプリケーションを含めた診断をおすすめしています。
                    </p>
                  </div>
                  <div>
                    <h3>AWS Amplifyは診断可能ですか？</h3>
                    <p>可能です。お気軽にご相談ください。</p>
                  </div>
                  <div>
                    <h3>どういった方を対象としていますか？</h3>
                    <p>
                      Firebaseをご利用の方を対象としています。AWS
                      Amplifyのように他のmBaaSも診断が可能です。
                    </p>
                  </div>
                  <div>
                    <h3>費用はどのくらいですか？</h3>
                    <p>
                      診断対象のヒアリングを行い費用を提示いたします。診断のご相談、見積のご依頼はお気軽にお問い合わせください。
                    </p>
                  </div>
                </FaqStyle>
              </Common2PaneSectionLayout>
            </Section>
          </LazyLoad>
        </div>
      </Assessment2PaneLayout>
    </>
  );
};

export const query = graphql`
  query FirebasePage {
    chart: file(relativePath: { eq: "assessment/wb_case1.png" }) {
      publicURL
    }
    allContentfulArticle(
      filter: {
        category: { slug: { eq: "firebase" } }
        slug: { ne: "disabled" }
      }
      sort: { fields: updatedAt, order: DESC }
    ) {
      nodes {
        ...AssessmentArticleListItemData
      }
    }
    contentfulArticleCategory(slug: { eq: "firebase" }) {
      ...AssessmentArticlaListCategory
    }
  }
`;

export default withContext(Component);
